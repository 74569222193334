export default function validateForm(
  formId,
  sendButton,
  inputClass,
  alertClass
) {
  const form = document.querySelector(formId);
  const sendBt = document.querySelector(sendButton);
  const fields = document.querySelectorAll(inputClass);
  const alert = document.querySelector(alertClass);
  const header = document.querySelector("#header");

  if (form === null) return;

  const errorSound = new Audio("./assets/sounds/error.mp3");

  let allowValidation = false;
  let errors = false;

  fields.forEach((field) => {
    field.addEventListener("keyup", () => {
      let html = [];
      allowValidation && validateField(field, html);
    });
  });

  sendBt.addEventListener("click", () => {
    let html = [];
    form.scrollIntoView({ block: "start" });
    errors = false;
    allowValidation = true;
    allowValidation &&
      fields.forEach((field) => {
        validateField(field, html);
      });

    setTimeout(() => {
      errors && formError();
      !errors && formSubmit();
    }, 1000);

    header.style.transform = "translate(0px, -100%)";
  });

  function validateField(field) {
    if ((field.value !== "" && field.value.length > 5) || field.checked) {
      field.classList.remove("input__error");
      field.classList.add("input__correct");
    } else {
      field.classList.add("input__error");
      field.classList.remove("input__correct");
      errors = true;
    }
  }

  function formError() {
    alert.style.display = "block";
    alert.classList.remove("form__alert--process");
    alert.classList.add("form__alert--error");
    alert.innerHTML =
      "<strong>Błąd:</strong> sprawdź czy pola formularza zostały poprawnie wypełnione.";
    errorSound.play();
  }

  function formSubmit() {
    alert.style.display = "block";
    alert.classList.remove("form__alert--error");
    alert.classList.add("form__alert--process");
    alert.innerHTML =
      "<strong>Sukces:</strong> Twoja wiadomość została wysłana. Dziękujemy!";

    setTimeout(() => {
      alert(
        "Musisz zdefiniować tutaj w kodzie zapytanie post i wrzucić odpowiedź w div lub błąd. validateForm.js 71"
      );
    }, 2000);
  }
}
