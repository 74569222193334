import "normalize.css";
import "./sass/app.scss";
import sliderSocial from "./js/slider-social";
import sliderNews from "./js/slider-news";
import mobileMenu from "./js/mobile-menu";
import cookiesBar from "./js/cookies-bar";
import stickyMenu from "./js/sticky-menu";
import validateForm from "./js/validateForm";
import lazyload from "./js/lazyload";

// menu
mobileMenu(".burger", "menu-active", ".menu__link");
stickyMenu("#header", "menu-bg");

// slider
sliderSocial();
sliderNews();

// lazyload
lazyload();

// contact
validateForm(
  "#contact-section",
  "#send-form",
  ".input--validate",
  ".form__alert"
);

// cookies
cookiesBar(".cookies", "show", "hide", ".button", 3000);
