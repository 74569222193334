function lazyload() {
  const images = document.querySelectorAll(".lazyload");

  if (images === null) return;

  const checkImages = () =>
    images.forEach((image, index) => {
      if (image.classList.contains("lazyload") && isInViewport(image)) {
        loadImage(image);
      }
    });

  function loadImage(image) {
    const src = image.dataset.src;
    image.src = src;
    image.classList.remove("lazyload");
  }

  window.addEventListener("scroll", checkImages);
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.bottom - element.offsetHeight <=
    (window.innerHeight || document.documentElement.clientHeight)
  );
}

export default lazyload;
