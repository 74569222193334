export default function mobileMenu(burgerClass, bodyClass, linkClass) {
  const body = document.body;
  const burgerButton = document.querySelector(burgerClass);
  const links = document.querySelectorAll(linkClass);

  links.forEach((link) => {
    link.addEventListener("click", () => {
      body.classList.toggle(bodyClass);
    });
  });

  burgerButton.addEventListener("click", toggleMenu);

  function toggleMenu() {
    body.classList.toggle(bodyClass);
  }
}
