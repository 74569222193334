export default function cookiesBar(
  selector,
  activeClass,
  closeClass,
  closeClassButton,
  delay
) {
  const cookiesBar = document.querySelector(selector);
  const closeButtons = document.querySelectorAll(closeClassButton);

  window.setTimeout(showBar, delay);
  if (closeClass !== null || closeClass.length !== 0) {
    closeButtons.forEach((button) => button.addEventListener("click", hideBar));
  }

  function showBar() {
    cookiesBar.classList.add(activeClass);
  }

  function hideBar() {
    cookiesBar.classList.add(closeClass);
  }
}
