import Swiper from "../../node_modules/swiper/dist/js/swiper";
import "../../node_modules/swiper/dist/css/swiper.css";

function sliderSocial() {
  const sliderNewsContainers = document.querySelectorAll(".slider-social");
  if (sliderNewsContainers === null || sliderNewsContainers === 0) return;

  const swiperSocial = new Swiper(".slider-social", {
    slidesPerView: "auto",
    spaceBetween: 40,

    breakpoints: {
      640: {
        spaceBetween: 20,
      },
    },
  });
}

export default sliderSocial;
