import Swiper from "../../node_modules/swiper/dist/js/swiper";
import "../../node_modules/swiper/dist/css/swiper.css";

function sliderNews() {
  const sliderNewsContainers = document.querySelectorAll(".slider-news");
  if (sliderNewsContainers === null || sliderNewsContainers === 0) return;

  const swiperNews = new Swiper(".slider-news", {
    slidesPerView: "auto",
    spaceBetween: 40,
    breakpoints: {
      640: {
        slidesPerView: "auto",
        spaceBetween: 20,
      },
    },
  });
}

export default sliderNews;
