export default function stickyMenu(menuID, bgClass) {
  const nav = document.querySelector(menuID);

  let lastScroll = 0;
  let allowChange = true;

  showHide();

  function showHide() {
    if (40 < window.scrollY) {
      if (!allowChange) return;
      allowChange = false;

      setTimeout(() => {
        allowChange = true;
      }, 100);

      nav.style.transition = "transform 0.2s ease, background-color 0.4s ease";

      const currentScroll = window.pageYOffset;

      if (currentScroll > lastScroll) {
        nav.style.transform = "translate(0, -100%)";
      } else {
        nav.style.transform = "translate(0, 0)";
      }

      lastScroll = currentScroll;
      nav.classList.add(bgClass);
    } else {
      nav.style.transform = "translate(0, 0)";
      nav.classList.remove(bgClass);
    }
  }

  window.addEventListener("scroll", showHide);
}
